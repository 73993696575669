import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-primary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "learning-support",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#learning-support",
        "aria-label": "learning support permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learning Support`}</h2>
    <p>{`The Learning Support (LS) programme is designed to help students develop independence, responsibility and skills in their academic, emotional and social lives at the school, so that they may have full and equal access to all parts of the PSI curricular and extra-curricular programmes. PSI is committed to using a range of strategies to support students, with the understanding that all students are served in their mainstream classes, with additional support provided by the Learning Support (LS) teacher and/or Learning Support Team (LST) working together with classroom teachers.`}</p>
    <p>{`It is important to note that the programme is designed to serve a small number of students in need of academic assistance or with mild disabilities. Students must demonstrate an appropriate level of independence and must meet the school’s social/behavioural expectations.`}</p>
    <p>{`PSI stresses the importance of all parties working collaboratively in support of the child. Parents, students, teachers and administration must agree to work together. This is especially important because Kyiv has limited resources available beyond what the school can offer.`}</p>
    <p>{`The School is committed to the Principles of Good Practice outlined by the IB in their document ‘Meeting student learning diversity in the classroom’ (2019):`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Affirming identity and building self-esteem`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Valuing prior knowledge`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Scaffolding learning`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Extended learning`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "learning-support-team",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#learning-support-team",
        "aria-label": "learning support team permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learning Support Team`}</h2>
    <p>{`The Primary School has a full time EC - G5 Learning Support Teacher and based on caseload, an appropriate number of teaching assistants for that department.`}</p>
    <h2 {...{
      "id": "guidance-counsellor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#guidance-counsellor",
        "aria-label": "guidance counsellor permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Guidance Counsellor`}</h2>
    <p>{`The counsellor implements a behavioural support programme and pastoral programme within the school. Students can be referred to the counsellor by the teacher, learning support programme, or parents for support with behaviour and emotional concerns in school. Further, the counsellor works with the teachers to implement the Keeping Safe Child Protection lessons and various other social emotional lessons connected to the Unit of Inquiries. The counsellor contributes to PSI’s child protection protocols and works with families and the community to address these concerns. The counsellor has an open door policy. Please contact the primary school counselor at `}<a parentName="p" {...{
        "href": "mailto:annieb@psi.kiev.ua"
      }}>{`annieb@psi.kiev.ua`}</a>{`.`}</p>
    <h2 {...{
      "id": "students-whose-needs-cannot-be-met",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#students-whose-needs-cannot-be-met",
        "aria-label": "students whose needs cannot be met permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Students Whose Needs Cannot Be Met`}</h2>
    <p>{`If the School determines a student's needs can not be met in the primary programme and / or is unable to proceed to Middle School, the parents are informed at the start of the second semester (January). A clear probation plan or transition plan is to be created by the start of the third quarter (March).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      